import React from 'react'
import {graphql} from 'gatsby'
import {mapEdgesToNodes} from '../lib/helpers'
import BlogPostPreviewGrid from '../components/blog-post-preview-grid'
import BackgroundImage from 'gatsby-background-image'
import Wrapper from '../components/wrapper'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'

export const query = graphql`
  query ArchivePageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      keywords
      innerImage {
      asset {
        fluid(maxHeight: 300) {
          ...GatsbySanityImageFluid_withWebp
        }
       }
      }
    }
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
      ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const ArchivePage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const postNodes = data && data.posts && mapEdgesToNodes(data.posts)
  const site = (data || {}).site 
  return (
    <Layout>
      <SEO
        title='Blog Archive'
        description='Post on topics of education, poetry, and programming.'
      />
      <BackgroundImage
        style={{
          backgroundPosition: 'top',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#ffffff',
          textShadow: '1px 2px 6px #000000',
          textAlign: 'center',
          height: 100
        }}
        fluid={site.innerImage.asset.fluid}
        backgroundColor={`#102514`}
      >
        <h2>Archive</h2>
      </BackgroundImage>
      <Wrapper>
        {postNodes && postNodes.length > 0 && <BlogPostPreviewGrid nodes={postNodes} />}
      </Wrapper>
    </Layout>
  )
}

export default ArchivePage
